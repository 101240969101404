import React, { useState } from 'react';
import axios from 'axios';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileData, setFileData] = useState({
        "desired_website": "",
        "keyword": "",
        "first_dwell_time": 0,
        "link_in_website": "",
        "second_dwell_time": 0,
        "number_of_clicks_per_day": 1
    });
    const [fileList, setFileList] = useState([]);

    const backendUrl = 'https://api.ggclicker.xyz';

    const handleError = (err) => {
    if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (err.response.data && err.response.data.message) {
            alert(err.response.data.message);
        } else {
            // Handle cases where 'data' does not contain 'message'
            alert('An unexpected error occurred');
        }
    } else if (err.request) {
        // The request was made but no response was received
        alert('No response was received');
    } else {
        // Something happened in setting up the request that triggered an Error
        alert('Error', err.message);
    }
    };

    const handleCreate = () => {
        axios.post(`${backendUrl}/create`, { fileName, data: fileData })
            .then(res => alert(res.data.message))
            .catch(handleError);
    };

    const handleEdit = () => {
        axios.post(`${backendUrl}/edit`, { fileName, data: fileData })
            .then(res => alert(res.data.message))
            .catch(handleError);
    };

    const handleList = () => {
        axios.get(`${backendUrl}/list`)
            .then(res => setFileList(res.data.files))
            .catch(handleError);
    };

    const handleDelete = () => {
        axios.post(`${backendUrl}/delete`, { fileName })
            .then(res => alert(res.data.message))
            .catch(handleError);
    };

    const fetchFileData = () => {
        axios.post(`${backendUrl}/edit`, { fileName })
            .then(res => setFileData(res.data))
            .catch(handleError);
    };

    if (!isAuthenticated) {
        return <Login onLoginSuccess={() => setIsAuthenticated(true)} />;
    }

    return (
            <div>
                <h1>Google Clicker Campaigns Manager</h1>
                <div>
                    <input type="text" value={fileName} onChange={e => setFileName(e.target.value)} placeholder="File Name" />
                    <button style={{ margin: '0 8px' }} onClick={handleCreate}>Create</button>
                    <button style={{ margin: '0 8px' }} onClick={fetchFileData}>Fetch Data</button>
                    <button style={{ margin: '0 8px' }} onClick={handleEdit}>Edit</button>
                    <button style={{ margin: '0 8px' }} onClick={handleList}>List</button>
                    <button style={{ margin: '0 8px' }} onClick={handleDelete}>Delete</button>
                </div>
                <div>
                    
                    <div>

                    <h2>File Data</h2>
                    <div style={{ display: 'grid', rowGap: '10px', columnGap: '10px', gridTemplateColumns: '250px 300px' }}>
                        {Object.keys(fileData).map(key => (
                            <React.Fragment key={key}>
                                <label>{key}:</label>
                                <input
                                    type={key.includes("_time") || key.includes("_day") ? "number" : "text"}
                                    value={fileData[key]}
                                    onChange={e => setFileData({ ...fileData, [key]: e.target.value })}
                                    style={{ width: '100%' }}  // Ensures the input fills the grid column
                                />
                            </React.Fragment>
                        ))}
                    </div>


                    </div>

                </div>
                <div>
                    <h2>File List</h2>
                    <ul>
                        {fileList.map(file => (
                            <li key={file}>{file}</li>
                        ))}
                    </ul>
                </div>
            </div>
        );
};

const Login = ({ onLoginSuccess }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const authenticate = () => {
        const hardcodedPassword = 'tim123'; // Replace with your actual password
        if (password === hardcodedPassword) {
            onLoginSuccess();
        } else {
            setError('Incorrect Password');
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
            />
            <button onClick={authenticate}>Login</button>
            {error && <p>{error}</p>}
        </div>
    );
};

export default App;